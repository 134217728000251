import * as React from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BankAccount } from '@/features/account/types';
import '@/componentsMui/Shared/Shared.css';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { getCurrencySign } from '@/features/fees/slice';
import { validateCardCreationAddress } from '@/features/card/slice';
import { Process } from '@/features/fees/types';
import { showException } from '@/features/swal/slice';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { Address, PotentialAddress } from '@/features/card/types';
import DeliveryAddressScreen from '../Components/DeliveryAddressScreen';
import DiplayBigNumber from '@/componentsMui/Shared/Components/DiplayBigNumber';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import PaymentAccount from '../Components/PaymentAccount';

interface CreateStepProps {
	option: Process,
	montlyFee: number,
	creationFee: number,
	activeAccount: BankAccount,
	accountList: BankAccount[],
	deliveryAddress: Address,
	confirmAddress: () => void,
	setDeliveryAddress: (value: Address) => void,
	setPotentialAddresses: (value: Array<PotentialAddress>) => void,
	onAccountChange: (newAccount: BankAccount) => void,
	prevStep: () => void,
	nextStep: () => void,
}

const CardDetails = ({ activeAccount, accountList, onAccountChange, option, prevStep, nextStep, confirmAddress, setDeliveryAddress, deliveryAddress, montlyFee, creationFee, setPotentialAddresses }: CreateStepProps) => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();
	const [loading, setLoading] = useState<boolean>(false);
	const [validationMessage, setValidationMessage] = useState('');
	const [missingComponents, setMissingComponents] = useState<Array<string>>([]);
	const cardCurrency = option.processProperties.ccy;
	const cardCurrencySymbol = getCurrencySign(cardCurrency);
	const totalPrice = montlyFee + creationFee;
	const insuficientFunds = activeAccount?.availableBalance < totalPrice;


	const handleNextStep = async () => {
		setLoading(true);
		const valid = await handleAddressValidation() || false;
		setLoading(false);
		if (valid) {
			nextStep();
		}

	};
	const handleAddressValidation = async () => {
		try {
			setMissingComponents([]);
			const response = await validateCardCreationAddress(deliveryAddress);

			const { valid, errorMsg, potentialAddresses, missingComponents: msComp } = response;
			if (valid) {
				return true;
			}
			if (!valid && errorMsg == 'invalidAddress') {
				setValidationMessage(t('cards.invalidAddress'));
				//console.log(msComp);
				if (msComp) {
					setMissingComponents(msComp);
				}
				return false;
			}
			if (!valid && errorMsg == 'multipleAddresses' && potentialAddresses?.length > 0) {


				const updatedPotentialAddresses = potentialAddresses.map(item =>
					item.address.country === 'UK' ? {
						address: {
							...item.address,
							country: 'United Kingdom'
						}, coordinates: { ...item.coordinates }
					} : item
				);


				setPotentialAddresses(updatedPotentialAddresses);
				confirmAddress();
				return false;
			}
		} catch (e) {
			await showException(e);
		}
	};

	return (

		<Grid container alignContent='space-between' flex={1}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<FormHeader title={option?.name} showBack={!!prevStep} onBack={prevStep} />
				</Grid>
				<Grid item xs={12}>
					<DiplayBigNumber
						prefix={<Typography variant='highlight' fontSize='1.5rem'>
							{cardCurrencySymbol}
						</Typography>}
						amount={creationFee}
						alwaysGradient={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<PaymentAccount
						accountList={accountList}
						insuficientFunds={insuficientFunds}
						activeAccount={activeAccount}
						onAccountChange={onAccountChange} />
				</Grid>
				<Grid item xs={12}>
					<StyledDivider />
				</Grid>
				<Grid item xs={12}>
					<DeliveryAddressScreen
						integration={option?.integration || ''}
						deliveryAddress={deliveryAddress} setDeliveryAddress={setDeliveryAddress}
						validationMessage={validationMessage}
						missingComponents={missingComponents} />
				</Grid>
			</Grid>
			<Grid item container xs={12} py={2} mt='auto' columnSpacing={2}>
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						onClick={closeModal}
						sx={{ width: { xs: '100%', md: 'auto' } }}
					>  {t('form.buttons.close')}
					</Button>
				</Grid>
				<Grid item xs={6} display='flex' justifyContent='flex-end'>
					<Button disabled={isEmpty(activeAccount) || isEmpty(deliveryAddress) || insuficientFunds || loading}
						key="buttonNext"
						variant='contained'
						color='primary'
						sx={{ width: { xs: '100%', md: 'auto' } }}
						onClick={handleNextStep}
					>
						{t('form.buttons.continue')}
					</Button>
				</Grid>

			</Grid>
		</Grid>

	);
};

export default CardDetails;

