
/* 
   IF KYC not passed, returns palceholder 
   IF there is error in loading service, shows errors
*/

import React from 'react';
import { showException } from '@/features/swal/slice';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { getKycTier, isKycTierVerified } from '@/helpers/kyc';
import { KycStatus, UserType } from '@/features/user/types';
import LoadingPageMui from '@/pages/LoadingPageMui';
import AccountsPlaceholder from './AccountsPlaceholder';
import { AccountType } from '@/features/operations/types';
import AccountsKycPlaceholder from './AccountsKycPlaceholder';


interface Props {
	type: AccountType,  // 'card' | 'account' | 'wallet' | 'vault' | 'trust';
	loading?: boolean,
	error?: string,
	children?: JSX.Element | JSX.Element[],
	checkList?: boolean,
	list?: any
}

const ServiceLoading = ({ type, error, children, loading }: Props): React.ReactElement => {

	//const [accountsLoading, setAccountsLoading] = useState<boolean>(true); 
	const { user } = useSelector((state: RootState) => state.user);
	const { skipKycAllowed, showAccountsIfSkipKycAllowed } = useSelector((state: RootState) => state.user.status);
	const tier = getKycTier(user);
	const kycTierVerified = isKycTierVerified(user);
	const poaVerified = user?.poa?.required ? user?.poa?.status === 'APPROVED' : true;
	const risksNotVerified = !user.risksVerified;
	const isKycTierRevoked = (user.kycTier === 0) && (user.previousKycTier > 0);
	//remove to eliminate blinking when backend mass updates accounts
	//if (accountsLoading || !user) return <CircularProgress />;

	const doNotShowAccountsPlaceholder = showAccountsIfSkipKycAllowed.includes(type) && skipKycAllowed;

	if (error) { showException(error); }


	if ((!poaVerified || tier?.status === KycStatus.IN_PROGRESS || risksNotVerified) && !user?.onlySkipKycUsers && !doNotShowAccountsPlaceholder) return <AccountsKycPlaceholder />;


	if ((user.type !== UserType.EMPLOYEE && (!kycTierVerified || !(user) || (user?.onBehalfOf === null && !kycTierVerified))) && !user?.onlySkipKycUsers && !doNotShowAccountsPlaceholder) {
		return isKycTierRevoked ? <></> : <AccountsPlaceholder type={type} kyc={true} />;
	}

	if (loading) {
		return <LoadingPageMui />;
	}



	return (
		<div>{children}</div>
	);
};

export default ServiceLoading;
