import * as React from 'react';

import { Dialog, Grid, IconButton } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { AccountIntegration, BankAccount } from '@/features/account/types';
import AccountDetails from '@/componentsMui/Banking/Account/AccountDetails';
import AccountIdentifier from '@/componentsMui/Banking/Account/AccountIdentifier';
import DepositDetails from '@/componentsMui/Banking/Transfer/AddMoney/Primetrust/DepositDetails';
import Spinner from '@shared/Widgets/Spinner/Spinner';
import AccountDetailsFormAsPdf from './AccountDetailsFormAsPdf';

interface Props {
	open: boolean,
	onClose: () => void,
	account: BankAccount,
	wireInstructions?: any,
}

const AccountDetailsForm = ({ open, onClose, account, wireInstructions }: Props) => {
	const { t } = useTranslation('translations');

	return (
		<Dialog
			id="account-details-form"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogContentNoScrollbar>
				<Grid
					container
					sx={{ p: { xs: '1rem', md: '2rem' } }}
				>
					<Grid item container justifyContent="space-between" alignItems="center" pb={6}>
						<Grid item>
							<MuiHeader>{t('banking.data.accountsDetails')}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item container alignItems='center' spacing={2}>
						<Grid item xs={12}>
							{account?.integration === AccountIntegration.PRIMETRUST ? <>
								{wireInstructions
									? <DepositDetails depositInstructions={wireInstructions} />
									: <Spinner />
								}
							</> : <AccountDetails account={account} />}
						</Grid>
						<Grid item xs={12} >
							<AccountIdentifier walletId={account?.walletId} hideHelperText={true} />
						</Grid>
						{account && <Grid item xs={12} justifyContent='center' >
							<AccountDetailsFormAsPdf account={account} />
						</Grid>}
					</Grid>
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	);
};

export default AccountDetailsForm; 
