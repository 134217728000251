import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { BankAccount } from '@/features/account/types';
import jsPDF from 'jspdf';
import { Button, Grid } from '@mui/material';

interface Props {
	account: BankAccount,
}

const AccountDetailsFormAsPdf = ({ account }: Props) => {
	const { t } = useTranslation('translations');

	const generatePDF = () => {
		const doc = new jsPDF();

		doc.setFont('helvetica', 'bold');
		doc.text(t('wallet.accountDetailsTitle'), 20, 20);

		doc.setFont('helvetica', 'normal');

		const details = [
			[t('wallet.beneficiary.name'), account.recipient],
		];

		if(account.iban && account.currency !== 'GBP') {
			details.push([t('wallet.beneficiary.iban'), account.iban]);
		}

		if(account.currency === 'GBP' && (account.providerType === 'POOLING_IBAN' || account.providerType === 'SCAN')) {
			details.push([t('wallet.beneficiary.accountNumber'), account.ukAccountNumber]);
			details.push([t('wallet.beneficiary.sortCode'), account.ukSortCode]);
		}

		details.push([t('wallet.beneficiary.bank'), account.bankName]);
		details.push([t('wallet.beneficiary.bankAddress'), account.bankAddress]);
		details.push([t('wallet.beneficiary.swift'), account.swift]);
		details.push([t('wallet.beneficiary.ccy'), account.currency]);
		details.push([t('wallet.beneficiary.accountIdentifier'), account.walletId]);

		let y = 40; // Start position
		details.forEach(([label, value]) => {
			doc.setFont('helvetica', 'bold');
			doc.text(label, 20, y);

			doc.setFont('helvetica', 'normal');

			// Automatically wrap text if too long
			const maxWidth = 80; // Maximum width for text
			const wrappedText = doc.splitTextToSize(value, maxWidth);

			doc.text(wrappedText, 120, y); // Draw wrapped text
			y += wrappedText.length * 10; // Adjust Y position for next line
		});

		doc.save(t('wallet.accountDetailsPdfFileName'));
	};

	return (
		<Grid container justifyContent='right'>
			<Button variant="simple" onClick={generatePDF}>{t('wallet.downloadAsPDF')}</Button>
		</Grid>
	);
};

export default AccountDetailsFormAsPdf; 
