import * as React from 'react';
import { Box, Button, Grid, Slider } from '@mui/material';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import './CryptoAsset.css';
import TokenAvatarStacked from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatarStacked';
import { TokenAvatarSizeEnum } from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatar';
import { CoinType, CoinTypeEnum } from '@/features/coins/types';
import AmountDifference from '@/componentsMui/Dashboard/HomeSummary/Components/AmountDifference';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import AmountCell from './AmountCell';
import { useTranslation } from 'react-i18next';
import bigDecimal from 'js-big-decimal';
import { isFiat } from '@/helpers/currency';
import { findProcessByType } from '@/features/operations/slice';
import { TransactionType } from '@/features/transactions/types';
interface Props {
	token: CoinType,
	hideValues: boolean,
	portfolioBalanceInEur: number,
	hideConvert: boolean,
}
const CryptoAssetLine = ({ token, portfolioBalanceInEur, hideValues, hideConvert }: Props) => {
	const history = useHistory();
	const { t } = useTranslation('translations');
	const isFiatLine = token?.type === 'WALLET';
	const isBlocked = token?.account.status === 'BLOCKED';
	const isTrust = token?.type === CoinTypeEnum.TRUST;
	const precision = token?.account?.precision ?? 5;
	const tokenBalanceInEur = token?.account?.balanceInEur;

	const tokenBalance = new bigDecimal(tokenBalanceInEur).round(2, bigDecimal.RoundingModes.DOWN);
	const portfolioBalance = new bigDecimal(portfolioBalanceInEur).round(2, bigDecimal.RoundingModes.DOWN);
	const share = tokenBalanceInEur && portfolioBalanceInEur && bigDecimal.compareTo(portfolioBalance.getValue(), 0) > 0 ? tokenBalance.divide(portfolioBalance, 2).multiply(new bigDecimal(100)).round(0, bigDecimal.RoundingModes.DOWN).getValue() : null;

	const isEur = token?.info?.symbol === 'EUR';
	const price = isEur ? 1 : token?.info?.summary24hInEur?.price;
	const changeAbsolute24h = isEur ? 0 : token?.info?.summary24hInEur?.changeAbsolute24h;
	const changePercent24h = isEur ? 0 : token?.info?.summary24hInEur?.changePercent24h;
	const showChange24h = !isFiat(token?.account?.currency) && token?.account?.type !== 'CRYPTO_TOKEN';

	const isCryptoTradeDown = findProcessByType(token, TransactionType.OWN_TRANSFER)?.maintenance;

	const openDepositForm = () => {
		history.push(`/wallet/home/wallets/deposit?symbol=${token.info.symbol}`);
	};

	const openSendForm = () => {
		history.push(`/wallet/home/wallets/send?symbol=${token.info.symbol}`);
	};

	const openDetails = () => {
		if (isFiatLine || hideConvert) return;
		history.push(`/wallet/home/wallets/details?token=${token.type}${token.info.symbol}`);
	};

	const hasBalance = bigDecimal.compareTo(token.account?.availableBalance, 0) > 0;

	return (
		<Box id='cryto-asset-line'
			className='cryto-asset'
			sx={{ cursor: isFiatLine ? 'auto' : 'pointer' }}>
			<Grid container alignItems='center' className='crypto-line'>
				<Grid className='crypto__asset' onClick={openDetails} >
					<TokenAvatarStacked
						symbol={token.info.symbol}
						logo={token.info.logo}
						name={token.info.displayName}
						variant={TokenAvatarSizeEnum.regular} withName />
				</Grid>
				<Grid className='crypto__price' onClick={openDetails}>
					{(token?.info?.summary24hInEur) &&
						<>
							<MuiTextLabel color='text.primary'>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									value={price}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									suffix=' EUR' />

							</MuiTextLabel>
							{showChange24h &&
								<AmountDifference
									fontSize='0.625rem'
									totalDiff={changeAbsolute24h}
									totalDiffPerc={changePercent24h * 100}
								/>}
						</>
					}
				</Grid>
				<Grid className='crypto__balance' onClick={openDetails}>
					<AmountCell
						amount={tokenBalanceInEur}
						currency='€'
						precision={2}
						hideValues={hideValues}
						color='text.primary' />
					<AmountCell
						amount={token?.account?.availableBalance}
						currency={token.account.currency}
						precision={precision}
						hideValues={hideValues}
						showCurrencyInSuffix={true}
						fontSize='0.625rem' fontWeight={400}>
					</AmountCell>
				</Grid>
				<Grid item className='crypto__portfolio' pr={2} onClick={openDetails}>
					<Slider
						defaultValue={0}
						value={parseFloat(share)}
						max={100}
						min={0}
						size='small'
						marks
						valueLabelDisplay='auto'
						sx={{
							'& .MuiSlider-thumb': {
								display: 'none'
							},
							padding: '0'
						}}
					/>
					<MuiTextLabel color='text.primary' fontWeight={400}>
						{share}%
					</MuiTextLabel>
				</Grid>
				<Grid
					className='crypto__actions'
					container
					justifyContent='flex-end'
					justifyItems='flex-start'
					flexWrap='wrap'
				>
					{!isFiatLine && !hideConvert &&
						<Grid item my='2px' >
							<Button variant="simpleDark" onClick={openDetails}
								disabled={(!token?.info?.sellEnabled && !token?.info.buyEnabled) || isCryptoTradeDown}>
								{t('wallet.labels.trade')}
							</Button>
						</Grid>
					}
					<Grid item ml='13px' my='2px'>
						<Button variant="simpleDark" onClick={openDepositForm} disabled={isTrust}>
							{t('wallet.labels.deposit')}
						</Button>
					</Grid>
					<Grid item ml='13px' my='2px'>
						<Button variant="simple" onClick={openSendForm} disabled={!hasBalance || isBlocked || isTrust}>
							{t('wallet.labels.transfer')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Box>);
};

export default CryptoAssetLine;
