import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Alert, Button, FormControlLabel, Grid, InputAdornment } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { useTranslation } from 'react-i18next';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { Form, Formik } from 'formik';
import StyledCheckbox from '@/componentsMui/Shared/Widgets/StyledCheckbox';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { isEmpty } from 'lodash';
import '@/componentsMui/Shared/Shared.css';
import '@/componentsMui/Shared/SharedFormLayout.css';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { getAllowedCountries } from '@/features/countries/slice';
import { InformationPayloadType, InformationType, SectionStatus, SectionType } from '@/features/business/types';
import { getBusiness, postBusinessInformation } from '@/features/business/slice';
import CountryCodesAlpha3 from '@/componentsMui/Shared/FormikComponents/CountryCodesAlpha3';
import { useAddress, useCompanyName, useCompanyRegistration, useDate, useEmail, usePhone } from '@/helpers/customHook/useValidations';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import { showException } from '@/features/swal/slice';
import { getLookupValues } from '@/features/fnd/fndSlice';
import FormikAutocomplete from '@/componentsMui/Shared/FormikComponents/FormikAutocomplete';
import { formatDateWithPattern } from '@/helpers/date';
import { isValid } from 'i18n-iso-countries';

interface Props {
	clientId?: string,
	section?: SectionType,
	setClient?: (value: any) => void,
	onNext?: () => void
}

const Information = ({ section, clientId, setClient, onNext }: Props): React.ReactElement => {

	const values = section?.values as InformationType;
	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [authorize, setAuthorize] = useState<boolean>((section?.status === SectionStatus.PENDING || section?.status === SectionStatus.REJECTED));
	const nameValidation = useCompanyName(t('createbusiness.information.name'));
	const dateValidation = useDate(t('createbusiness.information.date'));
	const addressValidation = useAddress(t('createbusiness.information.address'));
	const emailValidation = useEmail();
	const phoneValidation = usePhone();
	const registrationValidation = useCompanyRegistration(t('createbusiness.information.registration'));
	const [industryOptions, setIndustryOptions] = useState([]);
	const [companyTypeOptions, setCompanyTypeOptions] = useState([]);

	const doNotAllowUpdate = section?.status === SectionStatus.APPROVED;

	const getLov = useCallback(async (name: string) => {
		try {
			const result = await getLookupValues(name);
			const options = result?.map(p => ({
				code: p.lookupCode,
				label: p.description
			})) || [];

			if (name === 'BUSINESS_INDUSTRY_CODE') {
				setIndustryOptions(options);
			} else if (name === 'TYPE_OF_COMPANY') {
				setCompanyTypeOptions(options);
			}
		}
		catch (e) {
			console.log(e);
		}
	}, []);

	useEffect(() => {
		getLov('BUSINESS_INDUSTRY_CODE');
	}, [getLov]);

	useEffect(() => {
		getLov('TYPE_OF_COMPANY');
	}, [getLov]);

	const initialValues = {
		name: values?.companyName ?? '',
		registration: values?.companyRegNumber ?? '',
		date: values?.incorporationDate ?? '',
		country: values?.companyCountry ?? '',
		address: values?.companyAddress ?? '',
		email: values?.companyEmailAddress ?? '',
		phone: values?.companyPhoneNumber?.replace('+', '') ?? '',
		website: values?.companyWebsite ?? '',
		details: values?.companyDescription ?? '',
		companyCity: values?.companyCity ?? '',
		companyPostalCode: values?.companyPostalCode ?? '',
		industry: { code: values?.industryCode ?? '', label: values?.industry ?? '' },
		typeOfCompany: { code: values?.typeOfCompanyCode ?? '', label: values?.typeOfCompany ?? '' },
		authorize: ''
	};

	const validationSchema = Yup.object({
		name: nameValidation,
		registration: registrationValidation,
		date: dateValidation,
		country: Yup.string()
			.required(t('form.validator.required'))
			.test('country', t('form.validator.required'), (val) => {
				return val && val.length === 3 && isValid(val);
			}),
		address: addressValidation,
		email: emailValidation,
		phone: phoneValidation,
		companyCity: Yup.string().required(t('form.validator.required')),
		companyPostalCode: Yup.string().required(t('form.validator.required')).max(16, t('form.validator.maxChars', { chars: 16, field: t('createbusiness.information.companyPostalCode') })),
		industry: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		typeOfCompany: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		details: Yup.string().required(t('form.validator.required')).max(239, t('form.validator.maxChars', { chars: 240, field: t('createbusiness.information.detailsWithoutMax') }))
	});

	const submit = async (formData) => {
		const { name, registration, date,
			country, address, email,
			phone, website, details, companyCity, companyPostalCode, industry, typeOfCompany } = formData;
		try {

			const payload: InformationPayloadType = {
				companyName: name,
				companyAddress: address,
				companyCountry: country,
				companyWebsite: website,
				companyRegNumber: registration,
				incorporationDate: formatDateWithPattern(date, 'dd/MM/yyyy'),
				companyDescription: details,
				companyPhoneNumber: phone,
				companyEmailAddress: email,
				clientId: clientId,
				companyCity,
				companyPostalCode,
				industry: industry.label,
				industryCode: industry.code,
				typeOfCompany: typeOfCompany.label,
				typeOfCompanyCode: typeOfCompany.code
			};

			const result = await postBusinessInformation(payload);

			if (!clientId) {
				setClient && setClient(result?.clientId);
			}
			onNext && onNext();
		}
		catch (e) {
			showException(e);
		}
	};

	useEffect(() => {
		dispatch(getAllowedCountries());
	}, [dispatch]);


	const handleClose = () => {
		dispatch(getBusiness());
		closeModal();
	};



	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
		>
			{({ errors, isSubmitting, setFieldValue, handleSubmit, dirty, values }) => {
				return (
					<Form id='business-information' className='form-content_main-area-nopadding' style={{ width: '100%' }}>
						<Grid container columnSpacing={2}>
							{doNotAllowUpdate &&
								<Grid item xs={12} >
									<Alert severity="success">
										<MuiTextAlert> {t('createbusiness.information.approved')}</MuiTextAlert>
									</Alert>
								</Grid>
							}
							{section?.status === SectionStatus.REJECTED && section?.rejectionReason &&
								<Grid item xs={12} >
									<Alert severity="warning">
										<MuiTextAlert>{section?.rejectionReason}</MuiTextAlert>
									</Alert>
								</Grid>
							}
							<Grid item xs={12} >
								<MuiHeader className='business-header'> {t('createbusiness.information.title')}</MuiHeader>
							</Grid>
							<Grid item xs={12} sm={6}>
								<MuiTextField
									name="name"
									fullWidth
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
									label={t('createbusiness.information.name')} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<MuiTextField
									name="registration"
									fullWidth
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
									label={t('createbusiness.information.registration')} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<LocalizationProvider dateAdapter={DateAdapter}>
									<DesktopDatePicker
										onChange={(value) => setFieldValue('date', value)}
										value={values.date}
										label={t('createbusiness.information.date')}
										inputFormat="dd/MM/yyyy"
										disableFuture={true}
										minDate={new Date('1900-01-01')}
										openTo="year"
										views={['year', 'month', 'day']}
										renderInput={(params) => (
											<MuiTextField
												name="date"
												label={t('createbusiness.information.date')}
												fullWidth
												placeholder='DD/MM/YYYY'
												{...params}
												inputProps={{
													...params.inputProps,
													autoComplete: 'off',
													readOnly: doNotAllowUpdate
												}}
											/>)}

									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormikAutocomplete fieldName='industry'
									options={industryOptions} label={t('createbusiness.information.industry')}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormikAutocomplete fieldName='typeOfCompany'
									options={companyTypeOptions} label={t('createbusiness.information.companyType')}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<CountryCodesAlpha3 fieldName='country' />
							</Grid>
							<Grid item xs={12} sm={6}>
								<MuiTextField
									name="companyCity"
									fullWidth
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
									label={t('createbusiness.information.companyCity')} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<MuiTextField
									name="address"
									fullWidth
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
									label={t('createbusiness.information.address')} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<MuiTextField
									name="companyPostalCode"
									fullWidth
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
									label={t('createbusiness.information.companyPostalCode')} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<MuiTextField
									name="email"
									fullWidth
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
									label={t('createbusiness.information.email')} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<MuiTextField
									name="phone"
									fullWidth
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
									label={t('createbusiness.information.phone')}
									InputProps={{
										autoComplete: 'off',
										startAdornment: <InputAdornment position="start">+</InputAdornment>,
									}} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<MuiTextField
									name="website"
									fullWidth
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
									label={t('createbusiness.information.website')} />
							</Grid>
							<Grid item xs={12}>
								<MuiTextField
									name="details"
									fullWidth
									multiline
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
									label={t('createbusiness.information.details')} />
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									name='authorize'
									className='registration-input'
									disabled={doNotAllowUpdate}
									control={<StyledCheckbox name='authorize'
										onClick={() => setAuthorize(!authorize)}
										value={authorize}
										size='small'
										checked={authorize === true}
									/>}
									label={<MuiFormText>{t('createbusiness.information.authorize')}</MuiFormText>}
								/>
							</Grid>

						</Grid>
						<Grid container className='form-content_botton-area' >
							<Grid item xs={6}>
								<Button
									variant='customOutlined'
									onClick={handleClose}
								>  {t('form.buttons.cancel')}
								</Button>
							</Grid>
							<Grid item xs={6} container justifyContent='flex-end' columnGap={3}>
								{doNotAllowUpdate ? <Button
									variant='contained'
									// type="button"
									onClick={() => onNext && onNext()}
								>
									{t('form.buttons.continue')}
								</Button> : <Button disabled={isSubmitting || !isEmpty(errors) || authorize !== true}
									variant='contained'
									type="button"
									onClick={() => { dirty ? handleSubmit() : onNext && onNext(); }}
								>
									{t('form.buttons.continue')}
								</Button>}
							</Grid>
						</Grid>
					</Form >);
			}}
		</Formik >
	);
};

export default Information;
