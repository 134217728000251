import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Theme, } from '@mui/material';
import { User, UserType } from '@/features/user/types';
import { formatDateWithPatternIgnoreTimezone } from '@/helpers/date';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import MuiTextConfirmationLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextConfirmationLabel';
import { ReactComponent as MuiArrowCircle } from '@/assets/MuiArrowCircle.svg';
import { useTheme } from '@mui/styles';
import { isKycTierVerified } from '@/helpers/kyc';
import { capitalCaseString } from '@/helpers/normalizeString';

interface Props {
	user: User
}

interface ItemProps {
	label: string,
	children: React.ReactNode,
	hideDidiver?: boolean
}

const PersonalDetails = ({ user }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [countries] = useState(getNames('en'));
	const theme = useTheme() as Theme;
	const verified = isKycTierVerified(user);

	const DetailItem = ({ label, children, hideDidiver }: ItemProps) => (
		<Grid container>
			<Grid item xs={5}>
				<MuiTextConfirmationLabel> {label}</MuiTextConfirmationLabel>
			</Grid>
			<Grid item xs={7}>
				{children}
			</Grid>
			{!hideDidiver &&
				<Grid item xs={12} pt='1rem' pb='1rem'>
					<StyledDivider />
				</Grid>}
		</Grid>);

	return (
		<Grid container >
			{user.type === UserType.INDIVIDUAL ?
				<>
					<DetailItem label={t('profile.data.firstName')} >
						<MuiTextAlert> {user.firstName ? capitalCaseString(user.firstName) : 'N/A'} </MuiTextAlert>
					</DetailItem>
					<DetailItem label={t('profile.data.lastName')}>
						<MuiTextAlert> {user.lastName ? capitalCaseString(user.lastName) : 'N/A'} 	</MuiTextAlert>
					</DetailItem>
					<DetailItem label={t('profile.data.birthdate')}>
						<MuiTextAlert> {user.birthDate ? formatDateWithPatternIgnoreTimezone(user.birthDate, 'dd/MM/yyyy') : 'N/A'}</MuiTextAlert>
					</DetailItem>
					<DetailItem label={t('profile.data.country')}>
						<MuiTextAlert> {user.country ? countries[alpha3ToAlpha2(user.country)] : 'N/A'} </MuiTextAlert>
					</DetailItem>
					<DetailItem label={t('profile.data.address')} >
						{user.address ? <><MuiTextAlert> {user.address} </MuiTextAlert>
							<MuiTextAlert> {user.address2}  </MuiTextAlert>
							<MuiTextAlert> {user.postalCode} </MuiTextAlert>
							{user.city && <MuiTextAlert> {user.city} </MuiTextAlert>}
							<MuiTextAlert> {countries[alpha3ToAlpha2(user.addressCountry)]}</MuiTextAlert>
						</> :
							<MuiTextAlert>N/A</MuiTextAlert>}

					</DetailItem>
				</> :
				<>
					{user.type === UserType.BUSINESS ?
						<>
							<DetailItem label={t('profile.data.businessOwner')} >
								<MuiTextAlert>  {capitalCaseString(`${user.firstName} ${user.lastName}`)} </MuiTextAlert>
							</DetailItem>
							<DetailItem label={t('profile.data.companyName')} >
								<MuiTextAlert>  {user.companyName} </MuiTextAlert>
							</DetailItem>
							<DetailItem label={t('profile.data.regNumber')} >
								<MuiTextAlert>  {user.companyRegNumber} </MuiTextAlert>
							</DetailItem>
							<DetailItem label={t('profile.data.address')} >
								<MuiTextAlert>  {user.companyAddress} </MuiTextAlert>
							</DetailItem>
						</> : <>
							<DetailItem label={t('profile.data.firstName')} >
								<MuiTextAlert> {user.firstName ? capitalCaseString(user.firstName) : 'N/A'} </MuiTextAlert>
							</DetailItem>
							<DetailItem label={t('profile.data.lastName')}>
								<MuiTextAlert> {user.lastName ? capitalCaseString(user.lastName) : 'N/A'} 	</MuiTextAlert>
							</DetailItem>
						</>}
				</>
			}
			<DetailItem label={t('profile.data.verification')} hideDidiver >
				<Grid container justifyContent='space-between'>
					<Grid item xs={10}>
						<MuiTextAlert>
							{verified ? t('profile.activedata.verified') : t('profile.activedata.notverified')}
						</MuiTextAlert>
					</Grid>
					<Grid item xs={2} container justifyContent='flex-end'>
						<MuiArrowCircle style={{ fill: verified ? theme.statusColor.success : theme.statusColor.error }} />
					</Grid>
				</Grid>
			</DetailItem>
		</Grid >

	);
};

export default PersonalDetails;

