import { UserType } from '@/features/user/types';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';

export interface AllowedOperationType {
	path: string,
	allowed: boolean
}

const useUserOperations = () => {

	const { operations } = useSelector(
		(state: RootState) => state.operations
	);
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const walletOperations = ['CREATE_WALLET', 'CREATE_CRYPTO_ACCOUNT'];
	const showAccounts = operations.find(operation => operation === 'CREATE_ACCOUNT') && user.type !== UserType.EMPLOYEE && !user.hideMainAccountSection;
	const showCards = operations.find(operation => operation === 'CREATE_CARD');
	const showTrust = operations.find(operation => operation === 'CREATE_TRUST') && user.type !== UserType.EMPLOYEE;
	const showEarn = operations.find(operation => operation === 'CREATE_VAULT') && user.type !== UserType.EMPLOYEE;
	const showInvoices = operations.find(operation => operation === 'CREATE_INVOICE') && user.type !== UserType.EMPLOYEE;
	const showTokens = operations.find(operation => operation === 'CREATE_TOKEN_ACCOUNT') && user.type !== UserType.EMPLOYEE && ['DTS', 'VOOLA', 'MONETAL', 'DTSWAP'].includes(agent);
	const showWallet = operations.find(operation => walletOperations.includes(operation)) && user.type !== UserType.EMPLOYEE;

	const allowedOperations: AllowedOperationType[] =
		[{ path: 'accounts', allowed: showAccounts },
		{ path: 'cards', allowed: showCards },
		{ path: 'wallets', allowed: showWallet },
		{ path: 'earn', allowed: showEarn },
		{ path: 'tokens', allowed: showTokens },
		{ path: 'invoices', allowed: showInvoices },
		{ path: 'trust', allowed: showTrust }];

	return allowedOperations;

};

export default useUserOperations;
